$white: #fff;
$transparent_white: rgba($white, 0.1);

$black: #000;
$github_black: #333;

$gray: #707070;

$purple: #d7b8e0;

$blue: #2c6698;
$dark_blue: #1e476b;
$linkedin_blue: #2867b2;
$hyperlink_blue: #1f83db;

$green: #139b33;
