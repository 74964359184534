@import "../../common/color";
@import "../../common/grid";

.time-line {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 800px;
    margin: auto;
}

.title {
    color: $white;
    font-size: 60px;
    font-weight: bold;
    margin: 20px 0 40px 0;
    position: relative;
    overflow: hidden;
    padding: 20px;
    background: linear-gradient(to right, $blue, $blue);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

    .background-text {
        color: $transparent_white;
        position: absolute;
        font-size: 90px;
        left: 20px;
        top: -10px;
        z-index: -1;
        background: linear-gradient(
            to right,
            rgba($blue, 0.1),
            rgba($blue, 0.1)
        );
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
    }
}

.time-line-item {
    width: calc(100% - 40px);
    padding: 20px 20px 100px 20px;
    margin: auto;
    display: flex;
    position: relative;
    overflow: hidden;

    &:not(:last-child):after {
        content: "...";
        letter-spacing: 4px;
        font-weight: bold;
        color: $white;
        overflow-wrap: break-word;
        width: 1px;
        height: 70px;
        position: absolute;
        left: 50%;
        bottom: 20px;
    }

    .logo {
        width: 100px;
        height: 100px;
        padding: 10px;
        flex-shrink: 0;

        @media only screen and (max-width: $sm) {
            display: none;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .body {
        flex-grow: 1;
        padding: 10px;

        .name {
            display: flex;
            color: $white;
            font-size: 40px;
            font-weight: bold;
            position: relative;

            span {
                @media only screen and (max-width: $sm) {
                    font-size: 30px;
                    margin-left: 20px;
                }
            }

            .background-text {
                color: $transparent_white;
                position: absolute;
                font-size: 70px;
                left: 0;
                top: -30px;
                z-index: -1;

                @media only screen and (max-width: $sm) {
                    left: 50px;
                    font-size: 50px;
                    top: -20px;
                }
            }

            .mobile-logo {
                height: 50px;
                width: 50px;
                flex-shrink: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                @media only screen and (min-width: $sm) {
                    display: none;
                }
            }
        }

        .entry {
            margin-top: 20px;
        }

        .entry-name {
            font-size: 20px;
            font-weight: bold;
        }

        .date {
            font-size: 15px;
            margin: 10px 0;
        }

        .description {
            font-size: 20px;
            white-space: pre-line;
        }
    }
}

.time-line-link {
    display: flex;
    flex-direction: row-reverse;

    a {
        width: 40px;
        height: 40px;
        background: $transparent_white;
        color: $black;
        border-radius: 50%;
        margin: 5px;
        display: flex;
        cursor: pointer;

        &:hover {
            color: $white;

            &.github {
                background: $github_black;
            }

            &.hyperlink {
                background: $hyperlink_blue;
            }
        }

        svg {
            margin: auto;
        }
    }
}
